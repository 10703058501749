<!-- 刑满释放人员表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      @export="expor"
      @exportAll="exportAll"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="age" slot-scope="{ record }" class="operName">
        {{
          new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4))
        }}
      </div>
      <!-- 所在小区 -->
      <div slot="cellName" slot-scope="{ record }" class="operName">
        {{ record.cellName ? record.cellName : "--" }}
      </div>
      <!-- 原罪名 -->
      <div
        slot="originalCharge_dictText"
        slot-scope="{ record }"
        class="operName"
      >
        {{
          record.originalCharge_dictText ? record.originalCharge_dictText : "--"
        }}
      </div>
      <!-- 安置情况 -->
      <div slot="settleType_dictText" slot-scope="{ record }" class="operName">
        {{ record.settleType_dictText ? record.settleType_dictText : "--" }}
      </div>
      <!-- 危险性评估 -->
      <div
        slot="riskAssessment_dictText"
        slot-scope="{ record }"
        class="operName"
      >
        <a-button
          v-if="record.riskAssessment"
          :class="{
            grey: record.riskAssessment === '1',
            red: record.riskAssessment === '2',
          }"
          >{{
            record.riskAssessment_dictText
              ? record.riskAssessment_dictText
              : "--"
          }}</a-button
        >
        <a-input
          style="
            border: none;
            text-align: center;
            background-color: transparent;
          "
          v-else
          disabled
          value="--"
        ></a-input>
      </div>
    </ax-table>
    <!-- 新增刑满释放人员信息弹窗 -->
    <add-people ref="specialWomenInfo" @refsh="refsh"></add-people>
    <watch-People ref="specialWomenInfoDetail"></watch-People>
    <!-- 走访记录弹窗 -->
    <visit-records ref="visitRecords"></visit-records>
  </div>
</template>

<script>
import AddPeople from "./addPeople.vue";
import watchPeople from "./watchPeople.vue";

import VisitRecords from "./visitRecords.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所在小区",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "危险性评估",
    type: "select",
    model: "riskAssessment",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "安置情况",
    type: "select",
    model: "settleType",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { AddPeople, watchPeople, VisitRecords },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      currentAreaCode: "",
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 100,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 180,
          },
          {
            title: "年龄",
            dataIndex: "age",
            ellipsis: false,
            width: 60,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: true,
            width: 240,
          },
          {
            title: "所在小区",
            dataIndex: "cellName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "原罪名",
            dataIndex: "originalCharge_dictText",
            ellipsis: false,
            width: 220,
          },
          {
            title: "危险性评估",
            dataIndex: "riskAssessment_dictText",
            ellipsis: false,
            width: 140,
          },
          {
            title: "安置情况",
            dataIndex: "settleType_dictText",
            ellipsis: false,
            width: 240,
          },
        ],
        false, //去掉序号
        {
          fixed: "right",
          width: 220,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "走访",
                  name: "interview",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除当前内容?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增" },
        { name: "delect", text: "批量删除", type: "#F95A5A" },
        // { name: "inports", text: "导入", type: "#67C23A" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      currentAreaCode1: [],
      dataSourceApis: api.keyPopulationlist,
      dataSourceParams: {},
      selectedPeopleList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      const res = await api.keyPopulationlist(obj);
      this.$refs.tableBox.getDataSource(obj);
      this.selectedPeopleList = res.data.records;
      // return this.selectedPeopleList
      // console.log(this.selectedPeopleList, "表格已有数据");
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 走访
        case "interview":
          // record.title = '编辑精神病患者信息'
          this.$refs.visitRecords.openModal(record);
          break;
        // 编辑
        case "edit":
          record.title = "编辑刑满释放人员信息";
          this.$refs.specialWomenInfo.openModal(record);
          break;
        // 详情
        case "watch":
          record.title = "查看刑满释放人员信息";
          this.$refs.specialWomenInfoDetail.openModal(record);
          break;
        // 详情
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteKeyPopulationById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.getTableListData();
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.specialWomenInfo.openModal({
        title: "新增刑满释放人员",
        selectedPeopleList: this.selectedPeopleList,
      });
    },
    // 导出全部
    exportAll() {
      const values = "";
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "刑满释放人员信息.xls",
        params: values,
      });
    },
    // 导出
    expor() {
      this.searchForm.formValues.currentAreaCode = this.currentAreaCode;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "刑满释放人员信息.xls",
        params: values,
      });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteKeyPopulationByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 危险性评估
      await api
        .dictData({ dicKind: "emancipist_risk_assessment" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "riskAssessment",
            { options: { options, allowClear: true, placeholder: "请选择" } }
          );
        });
      // 安置情况
      await api.dictData({ dicKind: "emancipist_settle_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "settleType",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getTableListData();
    this.getDictionsary();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.red {
  background-color: #fff1f0;
  color: #cf1322;
  border-radius: 4px;
  border: #fff1f0;
}
// 解决固定列时出现空列问题
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
	border-left: none;
}
</style>