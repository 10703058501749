import { render, staticRenderFns } from "./watchPeople.vue?vue&type=template&id=997f0b9e&scoped=true"
import script from "./watchPeople.vue?vue&type=script&lang=js"
export * from "./watchPeople.vue?vue&type=script&lang=js"
import style0 from "./watchPeople.vue?vue&type=style&index=0&id=997f0b9e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997f0b9e",
  null
  
)

export default component.exports